import { appConfig } from "@/config/appConfig";
// DOCS: https://axios-http.com/zh/docs/intro
import { Message, Loading } from "element-ui";
import Axios from "axios";
import { MIME } from "@/api/MIME";
import { merge } from "lodash";
import store from "@/store";
import { isPlainObject } from "lodash";
import qs from "qs";
import { parseTime } from "@/utils/formatDate";
import { blobType } from "@/config/fileType";

const CancelToken = Axios.CancelToken;

// 存Axios实例
const axiosList = [];
let loadingNum = 0;
let loadingObj = null;

export class createYLHttp {
  // Axios实例
  axios = null;

  constructor(baseURL, config = {}) {
    const axios = Axios.create({
      baseURL,
      // 自定义属性 是否需要登录验证
      ignoreAuth: false,
      ...config,
    });

    axios.interceptors.request.use(
      this.axiosRequest.bind(this),
      this.requestError.bind(this)
    );
    axios.interceptors.response.use(
      this.axiosReponse.bind(this),
      this.responseError.bind(this)
    );

    this.axios = axios;
    axiosList.push(this);
  }

  /**
   * 取消请求
   * 已经响应了的请求, 无效
   * @param {*} requests
   * @param {*} msg
   * @returns
   */
  cancel(requests, msg = "") {
    // 取消所有
    if (!requests) {
      [...this.cancelMap.values()].forEach((cancel) => cancel && cancel(msg));
      return;
    }

    if (!Array.isArray(requests)) {
      requests = [requests];
    }

    // 取消指定请求
    requests.forEach((request) => {
      const cancel = this.cancelMap.get(request.requestId);
      this.cancelMap.delete(request);
      cancel && cancel(msg);
    });
  }

  get(url, config = {}) {
    // 取消请求 function
    let cancel;
    const requestId = this.genReuestId();

    const request = this.axios
      .get(
        url,
        merge(
          {
            requestId, // 自定义请求ID
            cancelToken: new CancelToken((c) => (cancel = c)),
          },
          config
        )
      )
      .catch((thrown) => {
        // 请求取消
        if (Axios.isCancel(thrown)) {
          console.warn(thrown.message);
          return;
        }

        throw thrown;
      });

    request.requestId = requestId;
    this.cancelMap.set(requestId, cancel);

    return request;
  }

  post(url, data = {}, config = {}) {
    // 取消请求 function
    let cancel;
    const requestId = this.genReuestId();

    config = merge(
      {
        requestId, // 自定义请求ID
        cancelToken: new CancelToken((c) => (cancel = c)),
        headers: {
          "Content-Type": MIME.FormUrlencoded,
        },
      },
      config
    );

    // 判断是对象&x-www-form-urlencoded
    // Object -> FormData
    if (
      isPlainObject(data) &&
      config.headers["Content-Type"].includes("x-www-form-urlencoded")
    ) {
      data = qs.stringify(data);
    }

    const request = this.axios.post(url, data, config).catch((thrown) => {
      // 请求取消
      if (Axios.isCancel(thrown)) {
        console.warn(thrown.message);
        return;
      }

      throw thrown;
    });

    request.requestId = requestId;
    this.cancelMap.set(requestId, cancel);

    return request;
  }

  // 拦截请求
  axiosRequest(config) {
    // 登录token
    const token = store.state.appToken;
    const BladeAuth = store.state.BladeAuth;

    if (config.params == null) {
      config.params = {};
    }
    return config;
  }

  // 拦截响应
  axiosReponse(response) {
    const { data, config, headers } = response;
    return data;
  }

  requestError(error) {
    console.log(error);
  }

  responseError(error) {
    console.log(error.response);
    if (
      error.response &&
      error.response.config &&
      error.response.config.loading
    ) {
      loadingNum -= 1;
    }
    if (
      loadingNum <= 0 &&
      error.response &&
      error.response.config &&
      error.response.config.loading
    ) {
      loadingObj.close();
      loadingObj = null;
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.type == "application/json"
    ) {
      var blob = new Blob([error.response.data], {
        type: "application/vnd.ms-excel",
      });
      //通过FileReader读取数据
      var reader = new FileReader();
      //以下这两种方式我都可以解析出来，因为Blob对象的数据可以按文本或二进制的格式进行读取
      //reader.readAsBinaryString(blob);
      reader.readAsText(blob, "utf8");
      reader.onload = function () {
        var content = this.result; //这个就是解析出来的数据
        console.log(JSON.parse(content));
        if (
          JSON.parse(content).code == 400 &&
          (JSON.parse(content).msg == "用户名或密码错误" ||
            JSON.parse(content).msg == "登录失败")
        ) {
          router.push({
            path: "/login",
          });
          // 超时, 清空登录token
          store.state.appToken = "";
          cancelAllRequest();
        }
        Message({
          message:
            JSON.parse(content) && JSON.parse(content).msg
              ? JSON.parse(content).msg
              : "系统异常",
          type: "error",
          duration: 5 * 1000,
        });
      };
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code == 400 &&
        (error.response.data.msg == "用户名或密码错误" ||
          error.response.data.msg == "登录失败")
      ) {
        router.push({
          path: "/login",
        });
        // 超时, 清空登录token
        store.state.appToken = "";
        cancelAllRequest();
      }
      Message({
        message:
          error.response && error.response.data && error.response.data.msg
            ? error.response.data.msg
            : "系统异常",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(error));
    }
    return Promise.reject(new Error(error));
  }

  urlParamFormat(data, headers) {
    if (
      isPlainObject(data) &&
      headers &&
      headers["Conent-Type"].includes("x-www-form-urlencoded")
    ) {
      return qs.stringify(data);
    }
    return data;
  }

  genReuestId() {
    return this.baseReuestId++;
  }
}

/**
 * 取消所有Axios实例的所有请求
 */
export function cancelAllRequest() {
  axiosList.forEach((axios) => {
    axios.cancel();
  });
}

export function createHttp(baseURL, config = {}) {
  return new HttpHelper(baseURL, config);
}

export function createMJHttp(baseURL, config = {}) {
  return new MJRequest(baseURL, config);
}

export function createMJHttp2(baseURL, config = {}) {
  return new MJRequest2(baseURL, config);
}

function getFilename(params) {
  let filename = "";
  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  var matches = filenameRegex.exec(params);
  if (matches != null && matches[1]) {
    filename = decodeURIComponent(matches[1].replace(/['"]/g, ""));
  }
  return filename.split(".")[0] + parseTime(new Date(), "{y}-{m}-{d}");
}
function getFilename2(params) {
  let filename = "";
  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  var matches = filenameRegex.exec(params);
  if (matches != null && matches[1]) {
    filename = decodeURIComponent(matches[1].replace(/['"]/g, ""));
  }
  return {
    filename: filename.split(".")[0] + parseTime(new Date(), "{y}-{m}-{d}"),
    filetype: filename.split(".")[1],
  };
}
