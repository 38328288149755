import { createYLHttp } from '@/api/WYHttpHelper';

const WLAxios = new createYLHttp('https://hnt.jetone.cn:8103');

// 角色管理列表
export const Authentication = (data) => {
   return WLAxios.axios({
      url: '/Api/Api/Login/Authentication',
      method: 'post',
      data
   });
};